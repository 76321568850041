<template>
  <div class="H5_headerBox">
    <div class="H5_headerBox-t">
      <!--        <div class="" >-->
      <!--            <img src="@/assets/img/h5logo.png"/>-->
      <!--        </div>-->

      <div class="H5_headerBox-left" @click="openMenu(USER_INFO)">
        <img class="logo" src="@/assets/img/h5logo.png"/>
        <div class="userInfo">
          <img class="avatar" :src="userInfo.avatar|| avatar"/>
          <div class="van-ellipsis username">
            {{ userInfo.nickName || $t('header.Nickname') }}
          </div>
        </div>
      </div>
      <van-icon name="bell" size="18" @click="openMenu(PRIVATE_CHAT)" v-if="privateFromUserId">
      </van-icon>
      <van-icon name="bars" size="18" @click="openMenu('MENUPOPUP')">
        <span class="menu">{{ $t('menu') }}</span>
      </van-icon>
    </div>
    <div class="H5_headerBox-b">
      <van-button type="danger" size="small" @click="getSignal()">
        {{ $t('signa.Trading signa') }}
      </van-button>
      <van-notice-bar
          ref="noticeBarRef"
          :text="lastSignalData.content"
      >
        <van-swipe
            ref="swiprRef"
            vertical
            class="notice-swipe"
            :autoplay="0"
            :show-indicators="false"
        >
          <van-swipe-item v-for="(item,index) in noticeList" :key="index">{{ item.content }}</van-swipe-item>
        </van-swipe>
        <template slot="right-icon">
          <div style="padding: 5px;" @click="nextNotice" v-if="noticeListLen">
            <van-icon name="arrow"/>
          </div>
        </template>
      </van-notice-bar>
      <div class="viewerContainer">
        <div class="label">{{ $t('signa.on line count') }}:</div>
        <div class="count">{{ onLineCount }}</div>
      </div>
      <!--      <div class="H5_headerBox-text" v-html="lastSignalData.content"></div>-->
    </div>
  </div>
</template>

<script>
import avatar from 'assets/img/avatar.png';
import { mapGetters, mapState } from 'vuex';
import { PICK_UP_SIGNAL, USER_INFO, CUSTOMER, REGISTER, LOGIN, PRIVATE_CHAT } from 'constants/sys';
import { getLastSignal, getNoticeList, getOnlineCount } from '@/api';
import { localStorage } from 'utils/storage';

export default {
  name: 'header-app.vue',
  data() {
    return {
      PRIVATE_CHAT,
      onLineCount: '-',
      timer: null,
      PICK_UP_SIGNAL,
      USER_INFO,
      avatar,
      dataForm: {
        teacherName: '',
        content: '',
      },
      noticeList: [],
    };
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    ...mapGetters(['userInfo', 'currentRoomId', 'lastSignalData']),
    ...mapState(['privateFromUserId']),
    noticeListLen() {
      return this.noticeList.length > 1;
    },
  },
  watch: {
    currentRoomId: {
      handler(id) {
        if (id) {
          getNoticeList({ roomId: id })
              .then((res) => {
                this.noticeList = res.data;
              });
          this.handleGetOnLineCount();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.handleGetOnLineCount();
      }, 5000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    handleGetOnLineCount() {
      if (!this.currentRoomId) {
        return;
      }
      getOnlineCount(this.currentRoomId)
          .then(res => {
            this.onLineCount = this.formatNumber(res.data);
          });
    },
    formatNumber(num) {
      if (!num) {
        return 0;
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
      } else {
        return num.toString();
      }
    },
    nextNotice(e) {
      console.log(e);
      // setTimeout(() => {
      this.$refs.swiprRef.next();
      // this.$refs.noticeBarRef.reset();
      // }, 1000);
    },
    getSignal() {
      const { tourist, token } = localStorage.get('authData') || {};
      if (tourist) {
        this.openMenu(REGISTER);
        return
      }
      // console.log('tourist:',tourist)
      // console.log('token:',token)
      // this.openMenu(REGISTER);
      // this.openMenu(PICK_UP_SIGNAL);
      getLastSignal({ roomId: this.currentRoomId })
          .then((res) => {
            if (res.data.canGetSignal) {
              this.openMenu(PICK_UP_SIGNAL);
            } else {
              layer.msg(this.$t('CantGetSignal tips'), {icon: 0,time:5000}, function(){
                // layer.msg('提示框关闭后的回调');
                // window.ECHAT.customMiniChat();
                window.open('https://t.me/XC600broker1', '_blank');

              });
            }
          });
    },
    // 打开菜单
    openMenu(type) {
      if (type === USER_INFO) {
        const { tourist } = localStorage.get('authData') || {};
        if (tourist) {
          this.$emit('menuClick', LOGIN);
          return;
        }
      }
      this.$emit('menuClick', type);
    },
  },
};
</script>

<style lang="less" scoped>
.H5_headerBox {
  color: #fff;

  .viewerContainer {
    padding: 4px;
    background-color: #ee0a24;

    .label {
      font-size: 13px;
    }

    .count {
      font-size: 14px;
      text-align: left;
    }
  }

  .menu {
    font-size: 14px;
  }

  .H5_headerBox-t {
    background-color: #191919;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .H5_headerBox-left {
      display: flex;
      align-items: center;
      //margin-left: 20px;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;

      .userInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        width: 0;
        margin-left: 4px;

        .avatar {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .username {
          font-size: 12px;
          flex: 1;
        }


      }


      .logo {
        width: 34px;
        //height: 36px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }

  .H5_headerBox-b {
    //padding: 10px;
    padding-left: 4px;
    background-color: #282828;
    display: flex;
    align-items: center;

    .van-button {
      margin-right: 10px;
    }

    .van-notice-bar {
      flex: 1;
      background: transparent;
      padding: 0;
      font-size: 16px;
    }

    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }

    .H5_headerBox-text {
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

</style>
